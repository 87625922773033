<template> 
    <div>
        <v-layout row wrap class="white elevation-6" style="border-radius: 10px">            
            <v-layout row wrap class="white--text primary darken-2 pa-2" style="border: 1px solid grey">
                <v-flex xs10>
                    <h3><b><i class="white--text fas fa-pen-to-square mt-3"> </i> Edit:</b> Nso People Association </h3>
                </v-flex>
                <v-flex xs2 text-xs-right>
                    <v-menu offset-y> 
                        <i slot="activator" class="fas fa-ellipsis-v fa-2x"></i>  
                        <v-list class="mt-0 pt-0"> 
                            <v-list-tile v-for="item in menuItems" :key="item.id" :to="item.link+$route.params.key" style="height: 40px;"> 
                                <v-list-tile-title class="pa-0 ma-0" style="">{{ item.title }}</v-list-tile-title> 
                            </v-list-tile> 
                        </v-list> 
                    </v-menu> 
                </v-flex>
            </v-layout>
            <v-flex xs12 text-xs-left class="pa-2">
                <v-card-text class="body-2 pa-2" style="font-size: 12px; padding: 5px">
                    <v-layout wrap>
                        <v-flex xs12 class="py-0 mt-0 mb-4">
                            <span class="caption"> <b>Njangi Description:</b> <p class="dark--text caption pa-0 ma-0 " blue v-html="njangi_group_description"></p></span>
                        </v-flex>
                        <v-flex xs12 sm12 md6 lg6 >
                            <v-layout row class="grey--text darken-2 mb-2">
                                <v-flex xs2 sm1>
                                    <i class="teal--text fas fa-phone fa-2x mt-2 "> </i>                            
                                </v-flex>
                                <v-flex xs10 sm10>
                                    <v-text-field class="pa-0" 
                                        id="njangi_contact" v-model="njangi_contact" ref="njangi_contact"
                                        :rules="[v => !!v || 'Njangi Contact is required']"
                                        label="Njangi Contact" 
                                        color="teal"
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12 sm12 md6 lg6 >
                            <v-layout row class="grey--text darken-2 mb-2">
                                <v-flex xs2 sm1>
                                    <i class="teal--text fas fa-money-bill fa-2x mt-2"> </i>                            
                                </v-flex>
                                <v-flex xs10 sm10>
                                    <v-text-field class="pa-0"
                                        id="contribution_amount" v-model="contribution_amount" ref="contribution_amount"
                                        :rules="[v => !!v || 'Contributions per person is required']"
                                        label="Contributions per person"
                                        color="teal"
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12 sm12 md6 lg6 >
                            <v-layout row class="grey--text darken-2 mb-2">
                                <v-flex xs2 sm1>
                                    <i class="teal--text fas fa-calendar-days fa-2x mt-2"> </i>                            
                                </v-flex>
                                <v-flex xs10 sm10>
                                    <v-select
                                        class="pt-0 mt-3" color="teal"
                                        v-model="collecting_cycle" ref="collecting_cycle"
                                        :items="collecting_cycles"
                                        :rules="[v => !!v || 'Meeting Time Interval is required']"
                                        label="Meeting Time Interval"
                                        required
                                    ></v-select>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12 sm12 md6 lg6 >
                            <v-layout row class="grey--text darken-2 mb-2">
                                <v-flex xs2 sm1>
                                    <i class="teal--text fas fa-calendar-days fa-2x mt-2"> </i>                            
                                </v-flex>
                                <v-flex xs10 sm10>
                                    <v-text-field class="pa-0"
                                        id="collecting_interval" v-model="collecting_interval" ref="collecting_interval"
                                        :rules="[v => !!v || 'No of days/weeks/months required']"
                                        :label="'Enter No of '+collecting_cycle"
                                        color="teal"
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12 sm12 md6 lg6 >
                            <v-layout row class="grey--text darken-2 mb-2">
                                <v-flex xs2 sm1>
                                    <i class="teal--text fas fa-users fa-2x mt-2"> </i>                            
                                </v-flex>
                                <v-flex xs10 sm10>
                                    <v-text-field class="pa-0 ml-2"
                                        id="no_people_collect_per_cycle" v-model="no_people_collect_per_cycle" ref="no_people_collect_per_cycle"
                                        :rules="[v => !!v || 'Number of people required']"
                                        label="People earning per cycle"
                                        color="teal"
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12 sm12 md6 lg6 >
                            <v-layout row class="grey--text darken-2 mb-2">
                                <v-flex xs2 sm1>
                                    <i class="teal--text fas fa-coins fa-2x mt-2"> </i>                            
                                </v-flex>
                                <v-flex xs10 sm10>
                                    <v-text-field class="pa-0"
                                        id="amount_deducted" v-model="amount_deducted" ref="amount_deducted"
                                        :rules="[v => !!v || 'Amount contributed for njangi is required']"
                                        label="Account per Meeting"
                                        color="teal"
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                    <div class="text-xs-center">
                        <v-btn @click="configureNewGroup()" class="white--text elevation-6" type="submit" round  color="teal" >
                            <span>Update</span>
                        </v-btn>
                    </div>
                    <v-spacer></v-spacer>
                </v-card-text>
            </v-flex>
        </v-layout>
        
        <br>
        <v-card color="white" style="border-radius: 10px">
            <v-card-text class="pa-2" style="font-size: 12px; cursor: pointer">
                <h2 class="blue--text">Group Description</h2>
                <v-layout row>
                    <v-flex xs2 sm1 class="text-xs-center">
                        <i class="teal--text fas fa-pen-to-square fa-2x mt-3"> </i>                            
                    </v-flex>
                    <v-flex xs9 sm10>
                        <v-textarea class="pa-0 mt-3"
                            name="input-7-1"
                            auto-grow v-model="njangi_group_description"
                            ref="njangi_group_description"
                            label="Group Description"
                            :rules="[v => !!v || 'Group Description is required']"
                            background-color="grey lighten-4"  color="teal"
                            rows="3" box
                            row-height="20" 
                        ></v-textarea>
                    </v-flex>
                    <v-flex xs2 >
                        <v-btn @click.stop="$store.commit('activateDialog', true); njangi_group_description_help = true; getHelpMessage()" flat fab small outline color="black" class="my-4" style="height:20px; width: 20px" >
                            <i class="fas fa-question"> </i>                            
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-card-text>
        <v-divider></v-divider>
            <v-card-text class="pa-2" style="font-size: 12px; cursor: pointer">
                <h2 class="blue--text">Group History</h2>
                <v-layout row>
                    <v-flex xs2 sm1 class="text-xs-center">
                        <i class="teal--text fas fa-pen-to-square fa-2x mt-3"> </i>                            
                    </v-flex>
                    <v-flex xs9 sm10>
                        <v-textarea class="pa-0 mt-3"
                            name="input-7-1"
                            auto-grow v-model="njangi_group_history"
                            ref="njangi_group_history"
                            label="Group History"
                            :rules="[v => !!v || 'Group History is required']"
                            background-color="grey lighten-4"  color="teal"
                            rows="3" box
                            row-height="20" 
                        ></v-textarea>
                    </v-flex>
                    <v-flex xs2 >
                        <v-btn @click.stop="$store.commit('activateDialog', true); njangi_group_history_help = true; getHelpMessage()" flat fab small outline color="black" class="my-4" style="height:20px; width: 20px" >
                            <i class="fas fa-question"> </i>                            
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-card-text>
        <v-divider></v-divider>
            <v-card-text class="pa-2" style="font-size: 12px; cursor: pointer">
                <h2 class="blue--text">Group Rules</h2>
                <v-layout row>
                    <v-flex xs2 sm1 class="text-xs-center">
                        <i class="teal--text fas fa-pen-to-square fa-2x mt-3"> </i>                            
                    </v-flex>
                    <v-flex xs9 sm10>
                        <v-textarea class="pa-0 mt-3"
                            name="input-7-1"
                            auto-grow v-model="njangi_group_rules"
                            ref="njangi_group_rules"
                            label="Group Rules"
                            :rules="[v => !!v || 'Group Rules is required']"
                            background-color="grey lighten-4"  color="teal"
                            rows="3" box
                            row-height="20" 
                        ></v-textarea>
                    </v-flex>
                    <v-flex xs2 >
                        <v-btn @click.stop="$store.commit('activateDialog', true); njangi_group_rules_help = true; getHelpMessage()" flat fab small outline color="black" class="my-4" style="height:20px; width: 20px" >
                            <i class="fas fa-question"> </i>                            
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-card-text>
            <div class="text-xs-center mb-2">
                <v-btn @click="configureNewGroup()" class="white--text elevation-6" type="submit" round  color="teal" >
                    <span>Update</span>
                </v-btn>
            </div>
        <v-divider></v-divider>
        </v-card>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {

        data() {
            return {
                dialog: false,
                njangi_invitation: '',
                njangi_group_name: '',
                njangi_group_description_help: '',
                njangi_group_description: '',
                njangi_group_history_help: '',
                njangi_group_history: '',
                njangi_group_rules_help: '',
                njangi_group_rules: '',
                njangi_contact: '',
                contribution_amount: '',
                collecting_interval: '',
                collecting_cycle: '',
                no_people_collect_per_cycle: '',
                amount_deducted: '',
                njangi_invitation_link: '',
                menuItems: [
                    {
                        id: '1',
                        title: 'Membership Settings',
                        link: '/njangi/groups/membership/',
                    },
                    {
                        id: '2',
                        title: 'Invite Others',
                        link: '/njangi/groups/new_group/three/',
                    },
                    {
                        id: '3',
                        title: 'View Complaints',
                        link: '/njangi/groups/complaints/',
                    },
                    {
                        id: '5',
                        title: 'Terminate Group',
                        link: '/njangi/groups/cancel/group/',
                    },
                ],
                collecting_cycles: [
                    "Days",
                    "Weeks",
                    "Months",
                ],
            }
        },
        mounted(){
            // executes these after the page has been mounted
            document.title = "PettyCash | Njangi Configuration"
            this.getNjangiGroup()
        },
        methods:{
            async getNjangiGroup(){
                this.$store.commit('setIsLoading', true)
                const group_id = this.$route.params.new_group_id  // get group id from url and send request to server
            
                await axios
                    .get('/api/v1/njangi/get/user/groups/detail/'+group_id+'/')
                    .then(response => {
                        this.njangi_group_name = response.data.njangi_group_name
                        this.njangi_group_description = response.data.njangi_group_description
                        this.njangi_contact = response.data.njangi_contact
                        this.contribution_amount = response.data.contribution_amount
                        this.collecting_interval = response.data.collecting_interval
                        this.collecting_cycle = response.data.collecting_cycle
                        this.no_people_collect_per_cycle = response.data.no_people_collect_per_cycle
                        this.amount_deducted = response.data.amount_deducted
                        this.njangi_invitation = 'Hello 😃, join our njangi group '+ response.data.njangi_group_name+'. '+ response.data.njangi_group_description + '. %0a '+response.data.get_invite_link  // get the data and fill into campaigns
                        this.njangi_invitation_link = response.data.get_invite_link  // get the data and fill into campaigns
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
        },
    }
</script>
